<template>
  <div class="box" :style="$store.state.displayArea.rightWidth + $store.state.displayArea.height">
    <div class="baidu-api" id="googlemap" v-show="mapType == 'Wgs84'"></div>
    <div class="search flex_align" v-if="isCustom">
      <div class="search_input flex_align">
        <el-autocomplete id="suggestId" style="width:100%;" @focus="focus" @blur="blur" v-model="search"
          :fetch-suggestions="querySearchAsync" :trigger-on-focus="false" :placeholder="$t('message.SearchLocation')"
          @select="handleSelect">
          <template slot-scope="{ item }">
            <div class="flex_align search_title" style="width:100%;">
              <i class="el-icon-search fl mgr10" style="margin-right: 10px;"></i>
              <div class="flex_align">
                {{ item.title }} <span v-if="item.address">({{ item.address }})</span>
              </div>
            </div>
          </template>
        </el-autocomplete>
        <div class="search_x mouse" @click="eliminate">×</div>
      </div>
      <div class="search_but mouse" @click="query()">
        <img src="@/assets/group/search.png" alt="" />
      </div>
    </div>
    <halfBall ref="halfball" @selectedBall="selectedBall"></halfBall>
    <mapcustomfence ref="mapcustomfence" :markList="markList" :mapIconDisplay="mapIconDisplay"
      @openDrawing="openDrawing" @drawPolylines="drawPolylines" @drawCircle="drawCircle" @drawRectangle="drawRectangle"
      @previousStep="previousStep" @deleteFenceArea="deleteFenceArea" @switchOnlineState="switchOnlineState"
      @changeMap="changeMap">
    </mapcustomfence>
    <addGroup ref="addGroup" v-if="isBool" :dialogTitle="dialogTitle" @clearOverwrites="clearOverwrites"
      :efId="$store.state.efId" :overlays="overlays"></addGroup>
    <msgTip :showcontent="msgTipData.showcontent" :tipstext="msgTipData.tipstext"></msgTip>
    <upmarker ref="upmarker" :markerUser="markerUser" v-if="dialogModifyMarker" @successMarker="successMarker"
      @cloneMarker="cloneMarker"></upmarker>
    <mapmark v-if="dialogModifyMark" @successMark="successMark" @cloneMark="cloneMark" :mark="mark" ref="mapmark">
    </mapmark>
    <div id="mouseOffset" v-if="editRegion != 1" :style="{
    position: 'absolute',
    'z-index': '9999999',
    top: offsetTop + 'px',
    left: offsetLeft + 'px',
    display: isShowMouse,
  }">
      {{ $t('message.doubleclick') }}{{ editRegion == 3 ? $t('message.Create') : $t('message.Save') }}
    </div>
  </div>
</template>

<script src=""></script>
<script>
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import axios from "axios";
import qs from "qs";
import mapcustomfence from "@/components/mapcustomfence.vue";
import msgTip from "@/components/history/deletepop.vue";
import addGroup from "@/components/dispatchLntercom/addGroup.vue";
import upmarker from "@/components/map/upmarker.vue";
import mapmark from "@/components/map/mapmark.vue";
import halfBall from "@/components/map/halfBall.vue";
import beasconfig from "@/api/beasconfig.js";
import { getQueryUserIcon,getQueryMark } from "@/administration/upmarker.js";
import EventBus from "@/eventBus/eventBus.js";
import { _throttle } from "@/method/utils.js";
import global from "@/method/globalMethod.js";
import m0 from "../../assets/map/m0.png";
import m1 from "../../assets/map/m1.png";
import m2 from "../../assets/map/m2.png";
import m3 from "../../assets/map/m3.png";
const gpsUrlapi = beasconfig.gpsUrlapi;
const regionUrlapi = beasconfig.regionUrlapi;
// console.log("gpsUrlapi", gpsUrlapi);
export default {
  name: "baiduMap",
  components: { mapcustomfence, msgTip,addGroup,upmarker,mapmark,halfBall },
  props: {
  },
  data() {
    return {
      mapIconDisplay: false,
      type: 1,
      isShow: true,
      msgTipData: {
        showcontent: false, //是否显示
        tipstext: "" //文字提示
      },
      overlays: {},
      coordinate: [], //全部对讲用户
      distinguishType: 0,
      traffic: "", //路况
      theFirstTime: 0, //初次
      frequency: 0, //次数
      temporaryTime: null, //计时器
      timeNum: 0, //计时时间
      oneself: {
        lat: "",
        lng: ""
      },
      search: "",
      recordType: 0, //记录类型
      groupData: [],
      pointPosition: [],
      searchLocation: [],
      pointList: "", //经纬度数据
      isBool: false, //显示编辑弹出框
      editRegion: 1, //编辑区域
      efId: 0,
      dialogTitle: this.$t('message.Createregionalgroups'),
      offsetLeft: 0,
      offsetTop: 0,
      isShowMouse: "none",
      regionType:0,
      userInfo:{}, //用户信息
      markerClusterer:null,  //百度地图聚合
      googleMarkerClusterer:null,  //谷歌地图聚合
      isCustom:true,
      markers:[],    //点聚合数组
      timeNum:0,  //判断谷歌地图是否加载完成
      mapType:'Wgs84',    //切换百度谷歌
      gooleCover: [],  //谷歌覆盖物数组
      gooleWc:false,    //谷歌地图是否加载完成
      mapTrafficLayer:null,  //路况图层
      googleDraw:null,    //谷歌绘制
      graphsDrawn:null,   //绘制的图形
      googleDistanceTool:null, //谷歌地图测距功能
      rangeArr:[],  //测距线条数组
      rangeCompleto:[],  //测距完成的数组
      rangeCompletoArr:{},  //测距多个数组
      dialogModifyMarker:false,
      dialogModifyMark:false,
      markerUser:{},
      markerUserOverlapId:0,
      mark:{},    //标记点
      recordMark:[],
      recordMarker:"",  //百度地图Marker记录点击的定位点
      markList:[]  //标记点
      // temporaryTimeOne:null,   //30秒没有操作就调用地图刷新
      // timeNumOne:0
    };
  },
  created() {
    // this.clearTimer();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  },
  // beforeMount: function() {

  // },
  beforeDestroy() {
    this.clearTimer();
    this.frequency = 0;
    this.theFirstTime = 0;
    this.$store.state.efRemark = "1";
    EventBus.$off("refreshMap");
    EventBus.$off("mapHoldall");
    EventBus.$off("position");
    EventBus.$off("changeMapOne");
    EventBus.$off("editEnclosureMap");
    EventBus.$off("emptyMap");
    EventBus.$off("mouseout");
    // 移除监听器，避免内存泄漏
    window.removeEventListener('click', this.clickShowUserInfo);
  },
  mounted() {
    let that = this;
    that.initMap();
    setTimeout(()=>{
      that.init();
    },500)
    window.closeLabel = this.closeLabel;
    window.mouseoverLabel = this.mouseoverLabel;
    window.clickShowUserInfo = this.clickShowUserInfo;
    window.expand = this.expand;
    window.overMap = this.overMap;
    window.outMap = this.outMap;
    // 监听全局鼠标点击事件
    window.addEventListener("click", this.clickShowUserInfo);
  },
  methods: {
    // 方法集合
    init() {
      let that = this;
      EventBus.$on("refreshMap", function() {
        //语音对讲
        that.startTime();
      });
      EventBus.$on("position", function(point) {
        //当前位置
        if(point){
          that.maps.setCenter({ lat: Number(point.lat), lng: Number(point.lng) });
        }else{
          if (that.pointPosition.length > 0) {
            that.setZoom(that.pointPosition,that.theFirstTime);
          } else {
            that.maps.setCenter({ lat: 38.903804230612835, lng: -77.0365677652717 });
          }
          that.search = "";
        }
        // that.removeMarkers();
      });
      EventBus.$on("mapHoldall", function() {
        //工具箱
      });
      EventBus.$on("editEnclosureMap", function (row) {
        //编辑区域群组
        that.clearTimer();
        that.theFirstTime = 0;
        that.$store.state.efId = row.regionId;
        if (row.regionId) {
          that.editRegion = 2;
          that.onmouseout(1);
          that.getRegionDetails();
        }
      });
      EventBus.$on("changeMapOne", function(row, type) {  //选中名称
        // that.clearTimer();
        that.recordType = type;
        that.$store.state.groupData = row == "" ? [] : [row];
        that.theFirstTime = 0;
        that.$store.state.efId = 0;    //每次点击清空
        that.editRegion = 1;
        if(row.regionId){
          that.$store.state.efId = row.regionId;
        }
        that.onmouseout();
        if(that.$store.state.efId > 0 && row.children && row.children.length <= 0){
          that.getRegionDetails()
        }
      });
      EventBus.$on("emptyMap", function (type) { //清除地图覆盖物
         if(type == 1){     //类型等于回退到最开始
          that.editRegion = 1;
          that.emptyData();
         }else{
          that.pointPosition = [];
          that.$store.state.groupData = [];
          that.$store.state.efId = 0;
          that.editRegion = type == 2 ? 1 : 3;    //2是删除区域群组
         }
         that.theFirstTime = 0;
          that.onmouseout();
         that.clearOverwrites();
      });
      EventBus.$on("mouseout", function () { //清除地图覆盖物
         that.onmouseout();
      });
    },
    cutMap(type) {   //切换地图
      if (this.mapType != type) {
        this.mapType = type;
        if (this.gooleWc == false) {  //判断地图是否有加载出来
          this.initMap();
        }
        this.closeGraphical();
        this.cleanupDraw();//先清除
        this.preventFrequentlyRefresh();
      }
    },
    roundPng(num){  //圆形图标
      let url = m0;
      if(num<=500){
        url = m0;
      }else if(num >=500 && num<=1000){
        url = m1;
      }else if(num >1000 && num<=2000){
        url = m2;
      }else{
        url = m3;
      }
      return url;
    },
    googleMarkerLabel(mark){   //谷歌label
          if(mark){
            return new MarkerWithLabel(mark);
          }
    },
    //谷歌地图
    initMap() {
      if (window.googleMap) {  //window.googleMap有值就代表地图API已经加载完成
        this.timeNum = 0;
        try {
          this.maps = new google.maps.Map(document.getElementById("googlemap"), {
            zoom: 10,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            //地图中心点，这里我以第一个数据的经纬度来设置中心点
            center: { lat: 38.903804230612835, lng: -77.0365677652717 },
            disableDefaultUI: false,
            zoomControl: false,
            maxZoom: 17,  //18
            minZoom:3,
            disableDoubleClickZoom: true,
            draggableCursor:'auto'
          });
          this.mapTrafficLayer = new google.maps.TrafficLayer();
          const renderer = {  //设置自定义图标
            render: ({ count, position }) =>
              new google.maps.Marker({
                label: { text: String(count), color: "#000000", fontSize: "12px"},
                position,
                icon: {
                  //设置集群的图片
                  url: this.roundPng(count),
                  // scaledSize: new google.maps.Size(65, 32),
                },
                // 设置zIndex
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
              }),
          };
          this.distanceMarkers = [];
          this.googleMarkerClusterer = new MarkerClusterer({map:this.maps,markers:[],renderer}); //聚合
          // 创建测距工具
          // this.googleDistanceTool = new MeasureTool(this.maps,{
          //     contextMenu:false,
          //     showSegmentLength: false,
          //     tooltip: false,
          //     unit: MeasureTool.UnitTypeId.METRIC // metric, imperial, or nautical
          // });
            // 保存测量的路径
            var time = null;
            // 创建一个标签来显示总距离
            // 监听地图的点击事件，添加测量点
            google.maps.event.addListener(this.maps, 'click', (event) =>{
              // console.log(path.getArray())
                if(this.distinguishType == 5){
                  var path = this.googleDistanceTool.getPath();
                  time = setTimeout(()=>{  //点击事件跟双击事件有冲突所以需要给个延迟
                      path.push(event.latLng); // 添加点到路径中
                      this.rangeArr.push(event.latLng);
                      //直接调用API接口来计算几条线段的长度和 单位米
                      let arr = path.getArray();
                      var distanceValue = google.maps.geometry.spherical.computeLength(arr);
                        distanceValue = `${(distanceValue/1000).toFixed(2)}Km`;
                     let rounddot = this.googleMarkerLabel({
                            position: arr[arr.length-1],
                            icon: {
                                url: ' '
                            },
                            map: this.maps, //地图实例
                            labelContent: `<div class='cj_circular mouse_dw'></div>`, //label的内容
                              // 调整框框
                            labelAnchor: new google.maps.Point(4, 6),
                        }); //圆形
                      let start = this.googleMarkerLabel({
                            position: arr[arr.length-1],
                            icon: {url: " "},
                            map: this.maps, //地图实例
                            labelContent:path.getArray().length > 1?distanceValue:this.$t('message.startingpoint'), //label的内容
                            // 调整框框
                            labelAnchor: new google.maps.Point(-10, 0),
                            labelStyle: {
                              padding: "0px 3px",
                              color: '#333333',
                              backgroundColor: '#ffffff',
                              borderRadius: '5px',
                              border:'1px solid #333333',
                              fontWeight: '400',  
                              opacity: 1
                            }
                        });
                        this.rangeCompleto.push(rounddot,start);
                  },200);
                }
            })
            // 添加双击事件监听器
           google.maps.event.addListener(this.maps,"dblclick",  () =>{
              clearTimeout(time);
              // 获取双击事件的经纬度
              // const latLng = event.latLng;
              // // 在控制台输出双击事件的经纬度
              // console.log("Double click at: " + latLng.lat() + ", " + latLng.lng());
              // this.googleDistanceTool.end();
              if(this.distinguishType == 5){
                this.rangingTotalLength();
              }
            });
            // 监听鼠标右键点击事件
            google.maps.event.addListener(this.maps, 'rightclick', () => {
              // 获取点击位置的经纬度
              if(this.distinguishType == 5){
                if(this.rangeArr.length > 1){
                  this.rangingTotalLength();
                }else{
                  this.cleanupDraw();//先清除
                }
              }
            });
          this.gooleWc = true;
        } catch (error) {
          this.gooleWc = false;
          // console.log("需要翻墙")
        }
      } else {
        setTimeout(() => {
          this.timeNum += 1;
          if (this.timeNum <= 10) {
            this.initMap();
          }
        }, 1000)
      }
    },
    rangingTotalLength(){  //测距保存
                  if(this.rangeArr.length > 0){
                      let marker = this.rangeCompleto[this.rangeCompleto.length-1];  //把最后一个添加的给删除
                      this.rangeCompleto.splice(this.rangeCompleto.length-1,1);  //删除最后一个重新添加
                      // let leng = this.rangeCompletoArr.length + 1;
                      marker.setMap(null);
                      var path = this.googleDistanceTool.getPath();
                      let arr = path.getArray();
                      var distanceValue = google.maps.geometry.spherical.computeLength(arr);
                      distanceValue = `${this.$t('message.totallength')}：${(distanceValue/1000).toFixed(2)}Km`;
                      let end = this.googleMarkerLabel({
                                  position: arr[arr.length-1],
                                  icon: {url: " "},
                                  map: this.maps, //地图实例
                                  labelContent:distanceValue, //label的内容
                                  // 调整框框
                                  labelAnchor: new google.maps.Point(-10, -10),
                                  labelStyle: {
                                    padding: "0px 3px",
                                    color: '#333333',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '5px',
                                    border:'1px solid #333333',
                                    fontWeight: '400',  
                                    opacity: 1
                                  }
                      });
                    var pint = this.$moment(new Date()).valueOf();
                    let close = this.googleMarkerLabel({
                                  position: arr[arr.length-1],
                                  icon: {url: " "},
                                  map: this.maps, //地图实例
                                  labelContent:`<div class="close_range" onclick="closeLabel(${pint},2,event)" >×</div>`, //label的内容
                                  // 调整框框
                                  labelAnchor: new google.maps.Point(-10, 10)
                      });
                      this.rangeCompleto.push(end,close);  //保存当前的数组
                    var line = new google.maps.Polyline({
                        path:this.rangeArr,
                        strokeColor: '#FF0000', // 线条颜色
                        strokeOpacity: 1.0, // 线条透明度
                        strokeWeight: 2, // 线条宽度
                        map: this.maps // 绑定地图实例
                      });
                }
                this.rangeCompletoArr[pint] ={
                  line:line,
                  marker:this.rangeCompleto
                };
                this.googleDistanceTool.setMap(null);
                this.rangeArr = [];  //绘制的线
                this.rangeCompleto = [];
                this.distinguishType = 0;
                this.maps.setOptions({draggableCursor: `auto`}); //设置谷歌地图鼠标图标
    },
    // 百度地图
    onmouseout(type){
      let that = this;
      if(that.distinguishType > 0){     //离开百度地图并且在绘制多边形的时候刷新地图
        that.isShowMouse = "none";
        that.distinguishType = 0;
        that.$refs.mapcustomfence.selectState.isCustom = false;
        that.isCustom = true;
        if(type != 1){    //不等于的话需要刷新
          setTimeout(()=>{
              that.switchOnlineState(that.type);
          },300)
        }
      }else{
          that.switchOnlineState(that.type);
      }
    },
    getRegionDetails(){
      axios.get(`${regionUrlapi}-${this.$store.state.userState.server}/region/${this.$store.state.efId}`).then((res) => {
          if(res.data.code == 200){
            // console.log(res)
            this.regionType = res.data.data.regionType;
            this.$store.state.efRemark = res.data.data.regionType;
            this.$store.state.efId = res.data.data.regionId;
            this.pointList = res.data.data.pointList;
            this.clearOverwrites(1);
             /*画围栏*/
            this.drawFence();
          }else{
            this.$MessageWarning(res.data.message);
          }
        })
    },
    /*画围栏*/
    async drawFence() {
        var that = this;
        var list = [];
        let color;
        let fillColor;
        if (that.editRegion == 1) {
              color = "#0064fc";
              fillColor = "rgba(22,111,245,0.2)";
        } else {
              color = "#ff3c00";
              fillColor = "rgba(255,60,0,0.3)";
        }
        if(that.$store.state.efRemark == "2"){
            var pointLists = that.pointList.split(";");
            var pointLists1 = pointLists.join('|').split("|");
            await pointLists1.map((item) => {
              var pt = item.split(",");
              var pts = {lat:Number(pt[0]),lng:Number(pt[1])};
              list.push(pts);
            });
        }else{
          var pointLists = that.pointList.split("|");
          await pointLists.map((item) => {
            var pt = item.split(",");
            var pts = {lat:Number(pt[1]),lng:Number(pt[0])};
            list.push(pts);
          });
          if(that.mapType == 'Wgs84'){
            // console.log(list)
            const bermudaTriangle = new google.maps.Polygon({
                        map:that.maps,
                        paths: list, 
                        strokeColor:"#FF0000", 
                        strokeOpacity: 0.8, 
                        strokeWeight: 2, 
                        fillColor:"#FF0000", 
                        fillOpacity: 0.35,
                        editable:true
            });
            google.maps.event.addListener(bermudaTriangle.getPath(), 'insert_at', function(evt) {   //监听多边形是否修改了数据
                    console.log(evt)
                    // let arr = bermudaTriangle.getPath();
                    // console.log(bermudaTriangle.getPath())
                    // console.log(bermudaTriangle.getPath().getAt(evt))
                    // console.log(bermudaTriangle.getPath().getAt(evt).toUrlValue(6))
            });
            google.maps.event.addListener(bermudaTriangle.getPath(), 'remove_at', function(evt) {   //监听多边形是否修改了数据
                    console.log(evt)
                    // let arr = bermudaTriangle.getPath();
                    // console.log(bermudaTriangle.getPath())
            });

            this.graphsDrawn = bermudaTriangle;
          }
        }
        if(that.editRegion == 1){
          let array = that.pointPosition;
          array.forEach(item1 =>{
            if(item1){
              var pts = new BMap.Point(item1.lng, item1.lat);
              list.push(pts);
            }
          })
        }
        setTimeout(function () {
          /*整体自适应居中*/
          that.setZoom(list,that.theFirstTime);
        }, 300);
      },
    eliminate() {
      //清除
      this.search = "";
      this.searchLocation = [];
    },
    querySearchAsync(queryString, cb) {
         let that = this;
      if (queryString != "" && queryString != null) {
        if(that.mapType == 'Wgs84'){
          // 创建 AutocompleteService 对象
         var autocompleteService = new google.maps.places.AutocompleteService();
          // 创建 Autocomplete 对象
         autocompleteService.getPlacePredictions({input: queryString}, 
          function(predictions, status) {
              let arr = [];
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                predictions.forEach((item)=> item.title = item.description);
                arr = predictions;
                cb(arr);
              }else{
                cb(arr);
              }
          });
        }
      }
    },
    query() {
      if (this.search != "" && this.search != null) {
           if(this.mapType == 'Wgs84'){
                this.maps.setCenter(this.searchLocation[0]);
           }
      } else {
        this.searchLocation = [];
      }
      EventBus.$emit("emptyChoice");
    },
    handleSelect(item) {
        let that = this;
        that.search = item.title;
        if(that.mapType == 'Wgs84'){
            // that.searchLocation = [item.point]; //搜索选中的定位信息
            // 使用 Geocoder 获取地点的详细信息
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({'placeId': item.place_id}, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                  let location = results[0].geometry.location;
                  that.maps.setCenter(location);
                  that.searchLocation = [location];
                }
            });
        }
    },
    getEnclosure(){   //更新区域群组
            let that = this;
            let array = this.overlays.Ro;
            let pointList = '';
            if (array) {
                // this.coordinateCalculationArea(array);
                that.pointList = '';
                for (var i = 0; i < array.length; i++) {
                    var lat = array[i].lat;
                    var lng = array[i].lng;
                    if (pointList == null || pointList == "") {
                        pointList = lng + "," + lat;
                    } else {
                        pointList = pointList + "|" + lng + "," + lat;
                    }
                }
                /*经纬度*/
                that.pointList = pointList;
            }
            let parmas = {
                regionType: that.$store.state.efRemark,//类型 1自定义 2区域
                pointList: that.pointList//经纬度数据
              };
              if (that.$store.state.efId > 0) {
                  parmas.regionId = parseInt(that.$store.state.efId) //围栏id
              }
              axios.post(`${regionUrlapi}-${that.$store.state.userState.server}/extregion/changePointList`, qs.stringify(parmas),{
            headers: beasconfig.tokenSl
          })
                  .then(res => {
                      if (res.data.code == 200) {
                          that.clearOverwrites();
                          that.emptyData();
                          that.$MessageSuccess(that.$t('message.message.update_successfully'));
                          setTimeout(()=>{      
                            that.switchOnlineState(that.type);
                          },300)
                      } else {
                          that.$MessageWarning(res.data.message);
                      }
                  })
    },
    startTime() {
      //30s刷新地图
      //开始计时
      let that = this;
      that.clearTimer();
      if(that.editRegion != 1){
        
        return
      }
      function timerTow() {
        return setTimeout(() => {
           if(that.editRegion == 1){
            that.preventFrequentlyRefresh();
           }
        }, 30000);
      }
      that.temporaryTime = timerTow();
    },
    clearTimer() {  // 清除定时器
      if(this.temporaryTime != null){
        // clearInterval(this.temporaryTime);
        clearTimeout(this.temporaryTime);
        // this.timeNum = 0;
        this.temporaryTime = null;
      }
    },
    changeMap(type, show) {
      //改变地图状态
      this.closeGraphical();
      if (type == 1) {
        //显示
        this.isShow = show;
        this.removeMarkers();
        if(show){
          this.$store.state.recordLable = [];   //如何点击从false为true就把记录删除Lable的值给回到最初。
        }
        if(this.$store.state.efId > 0 && this.$store.state.groupData.length >0 && this.$store.state.groupData[0].children &&this.$store.state.groupData[0].children.length <= 0){
          this.getRegionDetails()
        }else{
          this.switchOnlineState(this.type);
        }
      } else if (type == 2) {
        // 谷歌
        this.maps.setMapTypeId(show?google.maps.MapTypeId.ROADMAP:google.maps.MapTypeId.SATELLITE); //正常  卫星  
      } else if (type == 3) {
        if (this.mapTrafficLayer.getMap() && !show) {
          // 如果路况图层已经添加到地图上，则移除它
          this.mapTrafficLayer.setMap(null);
        } else {
          // 如果路况图层还没有添加到地图上，则添加它
          this.mapTrafficLayer.setMap(this.maps);
        }
      } else if (type == 4) {
        if (show) {
          //放大
          this.maps.setZoom(this.maps.zoom+1);
        } else {
          //缩小
          this.maps.setZoom(this.maps.zoom-1);
        }
      }else if(type == 5){   //测距
          if(this.mapType == 'Wgs84'){
            // 创建测距工具
            this.cleanupDraw();//先清除
            this.googleDistanceTool = new google.maps.Polyline({
              strokeColor: '#FF0000', // 线条颜色
              strokeOpacity: 1.0, // 线条透明度
              strokeWeight: 2, // 线条宽度
              map: this.maps // 绑定地图实例
            });
            this.maps.setOptions({draggableCursor: `crosshair`}); //设置谷歌地图鼠标图标
            this.distinguishType = 5;
          }
      }else if(type == 6){    //标注
            this.cleanupDraw();//先清除
            this.distinguishType = 4;
            this.drawShape();
      }
    },
    removeMarkers(){    //删除点聚合
      if(this.gooleCover.length > 0){
        this.googleMarkerClusterer.removeMarkers(this.gooleCover);
        this.gooleCover.forEach(item => item.setMap(null));
        this.gooleCover = [];
      }
    },
    cleanupDraw(){ //清除测距功能
      if(this.rangeArr.length > 0){  //如果是绘制的时候点直接清除
              this.rangeCompleto.forEach(item => item.setMap(null));
              this.googleDistanceTool.setMap(null);
              this.rangeArr = [];
              this.distinguishType = 0;
              this.maps.setOptions({draggableCursor: `auto`}); //设置谷歌地图鼠标图标
      }
    },
    switchOnlineState(type, name) {
      if (name) {
        this.$store.state.recordLable = [];
      }
      this.clearTimer();
      //切换在线状态
      this.coordinate = []; //每次更新数据都清空
      let arr = [];
      let userArray;
      let uid;
      let array =
        this.$store.state.groupData.length > 0
          ? this.$store.state.groupData
          : this.$store.state.groupUserData; //初始拿不到值的时候拿缓存值。
      if(this.$store.state.groupData.length > 0){   //首先判断是否有点击名称
        let Gid = this.$store.state.groupData[0].Gid?this.$store.state.groupData[0].Gid:this.$store.state.groupData[0].gid;
        array = [this.$store.state.groupUserData.find(item => item.Gid == Gid)];    //选中群组
        if(!this.$store.state.groupData[0].children){   //选中当个用户的时候
          array = [array[0].children.find(item => this.$store.state.groupData[0].id == item.id)];
        }
      }
      if (array.length > 0 && array[0].children) {
        array.forEach(item => {
          // console.log(item);
          arr.push(...item.children);
        });
      } else {
        arr = array;
      }
        if (type == 0 || this.recordType == 1 || !array[0].children) { //!array[0].children 判断点击的是群组还是用户
          //全部
          userArray = this.deweight(arr);
          uid = userArray.map(item => item.id);
        } else if (type == 1) {
          //在线人数
          userArray = this.deweight(arr).filter(item => item.online == 3);
          uid = userArray.map(item => item.id);
        } else {
          //不在线人
          userArray = this.deweight(arr).filter(item => item.online != 3);
          uid = userArray.map(item => item.id);
        }
      // }
      this.type = type;
      this.removeMarkers();
      this.pointPosition = [];
      this.coordinate = this.deweight(arr).filter(item => item.online == 3);
      this.getMarkList();
      if (userArray.length > 0) {
        // this.eliminateCheck(1);
        this.getAnchorPoint(uid, userArray);
      }else if(this.$store.state.efId > 0 && this.$store.state.groupData[0].children && this.$store.state.groupData[0].children.length > 0){
        this.getRegionDetails();
      }
      this.startTime();
    },
    getMarkList(){
      this.recordMark.forEach(item =>{
          item.setMap(null);
      })
      this.recordMark = [];
      getQueryMark({
        orgId: this.$store.state.userInfo.org_id
      }).then(res =>{
        let markList = res.data.data;
        if(markList.length > 0){
          markList.forEach(item =>{
            this.setMarkImg(item)
          })
          this.markList = markList;
        }else{
          this.markList = [];
        }
      })
    },
    /*用户id获取定位点*/
    getAnchorPoint(uid, users) {
      var that = this;
      let uids = uid.join(",");
      var params = {
        Uids: uids, //再线成员用户id
        CorrdinateType: that.mapType
      };
      getQueryUserIcon({
        orgId:this.$store.state.userInfo.org_id,
        userIds:uids
      }).then(row =>{
        let imgs = row.data.data;
        axios
        .post(
          gpsUrlapi + "/shanli/gps/api/locations/LastLocation?",
          qs.stringify(params),
          {
            headers: beasconfig.tokenSl
          }
        )
        .then(res => {
          // console.log("定位返回", res);
          if (res.data.Data.length > 0) {
            var locationData = res.data.Data;
            for (var i = 0; i < users.length; i++) {
              for (var j = 0; j < locationData.length; j++) {
                if (users[i].id == locationData[j].Uid) {
                  // console.log("id----1111", users[i].id);
                  users[i].pot = locationData[j];
                }
              }
            }
            var gps ={}; //找出相同的定位点
            users.forEach(function(item) {
              let n = imgs.find(m => m.user_id == item.id);
              if (item.pot) {
                if(n){
                  item.imgTabId = n.type;
                  item.iId = n.iId;
                  item.img = n.img;
                } 
                var pint =`${item.pot.Lng}${item.pot.Lat}`;
                if(gps[pint]){    //判断是否有这个数组
                  gps[pint].push(item);
                }else{
                  gps[pint]= [item];
                }
                if (that.type == 1) {
                  that.coordinate.push(item);
                }
                var pt;
                if(that.mapType == 'Wgs84'){
                  pt = item;
                }
                that.pointPosition.push(pt);
                item.pot.Rtime = that.$moment(Number(item.pot.Rtimestamp)).format("YYYY-MM-DD HH:mm:ss");
                item.isExceed = item.online==3?that.InspectionTime(item.pot.Rtime):false;
              }
            });
            for (const key in gps) {
              that.setMarker(gps[key]);
            }
            if(that.$store.state.efId > 0 &&  that.$store.state.groupData.length > 0){
              that.getRegionDetails();
            }else{
              that.setZoom(that.pointPosition,that.theFirstTime);
            }
          } else {
            if (that.recordType == 1) {
              that.nullMessage();
            }
          }
        });
      })
    },
    nullMessage: _throttle(function() {
      //没数据的时候提示
      this.recordType = 0;
      this.$MessageWarning(this.$t('message.noData'));
    }, 500),
    preventFrequentlyRefresh:_throttle(function() {   //阻止频繁刷新
        this.switchOnlineState(this.type);
    }, 1000),
    eliminateCheck(type){   //清除绘制选中
        if(this.distinguishType != 0){
          if(type != 1){
            this.clearOverwrites();
          }
          this.drawingManager.close();  //刷新就重置绘图工具
          this.$refs.mapcustomfence.selectState.isCustom = false;
          this.isCustom = true;
          this.distinguishType = 0;
        }
      },
    deweight(arr) {
      //数组去重
      let de_arr = [];
      arr.forEach(i => {
        let isTrue = de_arr.every(j => j.id != i.id);
        isTrue ? de_arr.push(i) : "";
      });
      return de_arr;
    },
    closeLabel(id,type,event){  //移除label
      if(type == 1){  //1是移除label
        var t = document.getElementById(`baidu${id}`);//选取label为百度baidu的id进行隐藏
        t.style.display = 'none';// 以块级样式隐藏
        t.remove();
        this.gooleCover.forEach(item =>{
          if(item.id == id){
              item.labelContent = "";
              item.setMap(null);  //先删除在添加防止用户重叠点不到关闭
              item.setMap(this.maps); 
              // item.setLabel(null);
          }
        })
        this.$store.state.recordLable.push(id);
      }else if(type == 2){  //2是移除测距功能
        this.rangeCompletoArr[id].line.setMap(null);
        this.rangeCompletoArr[id].marker.forEach(item => {  //清空marker
          item.setMap(null)
        })
        delete this.rangeCompletoArr[id];  //删除当前的对象值
      }
      event.preventDefault();
        // 阻止事件冒泡
      event.stopPropagation();
    },
    mouseoverLabel(id,type){
      if(type == 1){   //1是鼠标经过 0是离开
        this.markerZindex(id);
      }
      this.showMarkerLabel(id,type);
    },
    InspectionTime(time){   //验证时间
      let Rtime = this.$moment(time).valueOf(); //定位上报时间
      let Dtime = this.$moment(new Date()).valueOf(); //当前时间
      let Ztime = (60 * 1000) * 5; //5分钟
      let Mtime = Dtime - Rtime;  //当前时间减去定位上报时间
      let isExceed = Mtime >= Ztime;  //判断是否超过五分钟
      return isExceed
    },
    markerZindex(id){
            if(this.mapType == 'Wgs84'){
              this.gooleCover.forEach(marker =>{
                if(marker.id == id){   //判断是否为当前markerLabel 如果是则将优先级设为最高
                  marker.setZIndex(9999)
                }else{
                  marker.setZIndex(0)
                }
              })
            }
    },
    showMarkerLabel(id,type){ //显示lable时间
              var t = document.getElementById(`rtime${id}`);//选取label为百度baidu的id进行隐藏
              var labelId = document.getElementById(`rtimelabel${id}`);
              if(labelId) labelId.style.display = type == 1?'none':'block';// 以块级样式隐藏
              if(t){
                if(type == 1){
                  t.style.display = 'block';// 以块级样式隐藏
                }else{
                  t.style.display = 'none';// 以块级样式隐藏
                }
              }
    },
    expand(id,type){  //展开用户信息
      var z = document.getElementById(`expand2${id}`);//展开
      var elements = document.querySelectorAll(`#expand1${id}`);
      elements.forEach(function(element) {  
        element.style.display = type == 2?'block':'none';// 以块级样式隐藏
      });
      z.style.display = type == 2?'none':'block';// 以块级样式隐藏
    },
    clickShowUserInfo(id,zid,event){  //展示用户信息
      // zid 是父盒子的id
      if(id && zid){  //判断是点击的用户
        this.markerUserOverlapId = zid;
        let m = this.markers.find(item => item.row.id == zid);
        let row = m.arr.find(item => item.id == id);
        var t = document.getElementById(`userMarker${zid}`);//选取label为百度baidu的id进行隐藏
        t.style.display = 'block';// 以块级样式隐藏
        t.innerHTML = global.multipleMapHtml(1,row);
        event.preventDefault();
        // 阻止事件冒泡
        event.stopPropagation();
      }else{
        if(this.markerUserOverlapId > 0){
          var t = document.getElementById(`userMarker${this.markerUserOverlapId}`);//选取label为百度baidu的id进行隐藏
          if(t){
            t.style.display = 'none';// 以块级样式隐藏
            this.markerUserOverlapId = 0;
          }
        }
      }
    },
    successMark(){  //成功修改自定义照片
      this.getMarkList();
      this.dialogModifyMark = false;
    },
    cloneMark(){  //关闭自定义图片弹出框
      this.getMarkList();
      this.dialogModifyMark = false;
    },
    setMarkImg(item){  //设置图标
            /*自定义图标*/
            let imgUrl = item.img?`${beasconfig.xinextendUrlapi}/icon${item.img}`:require("@/assets/map/mark.png");
            if(this.mapType == 'Wgs84'){
             let marker = new google.maps.Marker({
                position: {lat:Number(item.lat),lng:Number(item.lng)},
                map: this.maps,
                // title: '自定义标注',
                icon: imgUrl // 设置自定义图标
              });
              marker.addListener('click',()=>{
                this.mark = item;
                this.dialogModifyMark = true;
            });
            this.recordMark.push(marker);
            }
    },
    successMarker(item){  //成功修改自定义照片
      let imgUrl = "@/assets/map/djj.png";
      let bool = item.online==3?this.InspectionTime(item.pot.Rtime):false;
      if(item.online != 3){
        imgUrl = require("@/assets/map/djj.png");
      }else{
        imgUrl = bool?require("@/assets/map/djj2.png"):require("@/assets/map/djj1.png");
      }
      imgUrl = item.img?`${beasconfig.xinextendUrlapi}/icon${item.img}`:imgUrl;
      if(this.mapType == 'Wgs84'){
        this.recordMarker.setIcon(imgUrl);  
      }
      this.dialogModifyMarker = false;
    },
    cloneMarker(){  //关闭自定义图片弹出框
      this.dialogModifyMarker = false;
    },
    selectedBall(){   //选择半球
      this.switchOnlineState(this.type);
    },
    /*创建定位点*/
    setMarker(arr) {
      let item = arr[0];
      item.pot.Lng = global.JudgingHemisphere(item.pot).Lng;
      item.pot.Lat = global.JudgingHemisphere(item.pot).Lat;
      let bool = arr.length > 5;
      let isLabel = this.$store.state.recordLable.includes(item.id);  //判断当前label是否有关闭
      let isExceed = item.isExceed;  //判断是否超过五分钟
      var html = "";
      if(arr.length > 1){
          html = global.multipleMapHtml(2,item,arr,this.mapType);
      }else{
          html = global.mapHtml(item,this.mapType);
      }
      /*自定义图标*/
      let imgUrl = "@/assets/map/djj.png";
      if(item.online != 3){
        imgUrl = require("@/assets/map/djj.png");
      }else{
        imgUrl = isExceed?require("@/assets/map/djj2.png"):require("@/assets/map/djj1.png");
      }
      imgUrl = item.img?`${beasconfig.xinextendUrlapi}/icon${item.img}`:imgUrl;

      if(this.gooleWc){
        let googleAnchor = {
          top:bool? (42 * 5 + 50):arr.length > 1?(42 * arr.length + 36):35,
          left:bool?120:arr.length > 1? 112:85
        };
        var position = new google.maps.LatLng(item.pot.Lat, item.pot.Lng);
        const markerLabel = new MarkerWithLabel({
            id:item.id,
            position: position,
            icon: {
              url: imgUrl,
              // 设置图片的大小和偏移量
              // size: new google.maps.Size(32, 32),
              // anchor: new google.maps.Point(16, 15)
            },
            map: this.maps, //地图实例
            labelContent:this.isShow && !isLabel?html:' ', //label的内容
            // 调整框框
            labelAnchor: new google.maps.Point(googleAnchor.left, googleAnchor.top),
            labelStyle: {
              padding: "0px",
              color: '#ffffff',
              // backgroundColor: 'rgb(103, 197, 197)',
              // borderRadius: '5px',
              fontWeight: '400',
              opacity: 1
            },
            labelClass: bool?'googleLabel':'' //'googleLabel'//bool?'googleLabel':'' //'googleLabel'
        });
        markerLabel.addListener('click',()=>{
          this.markerUser = item;
          this.dialogModifyMarker = true;
          this.recordMarker = markerLabel;
          this.markerZindex(markerLabel.id);
        });

        // markerLabel.addListener("mouseover", () => {
        //   this.markerZindex(markerLabel.id);
        //   this.showMarkerLabel(markerLabel.id,1)
        // });
        // markerLabel.addListener("mouseout", () => {
        //   // this.markerZindex(markerLabel.id);
        //   this.showMarkerLabel(markerLabel.id,0)
        // });
        // setTimeout(()=>{
        //   var elements = document.querySelectorAll(`.markerLabels`);
        //   elements.forEach(function(element) {  
        //     console.log(element,111111111111)
        //     element.removeAttribute("onselectstart");  
        //     element.removeAttribute("ondragstart");
        //   });
        // },1000)
        this.gooleCover.push(markerLabel);
        this.googleMarkerClusterer.addMarker(markerLabel);
      }
    },
    overMap(id){  //谷歌地图
      this.markerZindex(id);
      this.showMarkerLabel(id,1)
    },
    outMap(id){
      this.showMarkerLabel(id,0)
    },
     // 根据点的数组自动调整缩放级别
     setZoom(bPoints) {
      if (this.mapType == 'Wgs84') {
        if (bPoints.length > 0) {
          var latlngbounds = new google.maps.LatLngBounds();  //根据点的数组自动调整缩放级别
          bPoints.forEach(item => {
            let lat = item.pot?item.pot.Lat:item.lat;
            let lng = item.pot?item.pot.Lng:item.lng;
            latlngbounds.extend(new google.maps.LatLng(lat, lng));
          })
          this.maps.fitBounds(latlngbounds);
          this.maps.panToBounds(latlngbounds);
        }
      }
    },
    /*绘制入口*/
    openDrawing() {
      this.clearOverwrites();
      this.$refs.mapcustomfence.selectState.isCustom = false;
      this.isCustom = true;
    },
    drawShape(){   //绘制地图形状
        let that = this;
        if(that.distinguishType != 4){
          that.isCustom = false;
          that.mapIconDisplay = false;
          that.clearTimer();
        }
        let color;
        let fillColor;
        let strokeWeight = 1;
        if (that.editRegion == 1) {
            color = "#0064fc";
            fillColor = "rgba(22,111,245,0.2)";
        } else {
            color = "#ff3c00";
            fillColor = "rgba(255,60,0,0.3)";
        }
        let index = that.distinguishType - 1;
        if(this.mapType == 'Wgs84'){
          let drawingOptions = [{
          'polygonOptions':''   //多边形
        },{
          'circleOptions':''   //圆形
        },{
          'rectangleOptions':''
        },{
          'markerOptions':''
        },{
          'polylineOptions':''
        }];
        let modeArr = [google.maps.drawing.OverlayType.POLYGON,google.maps.drawing.OverlayType.CIRCLE,google.maps.drawing.OverlayType.RECTANGLE,google.maps.drawing.OverlayType.MARKER,google.maps.drawing.OverlayType.POLYLINE];
        let opions = drawingOptions[index];
        let key = Object.keys(opions)[0];
        if(index == 4){
          color = "#FF0000";
          fillColor = "#FF0000";
          strokeWeight = 2;
        }
        opions[key] = {
                  strokeColor: color,
                  fillColor: fillColor,
                  // fillOpacity: "var(--main-color)",
                  strokeWeight:strokeWeight,
                  clickable: false,
                  editable: false,
                  strokeStyle:'dashed',
                  zIndex: 1,
                  icon: require("@/assets/map/mark.png") // 初始图标路径  
              };
       
        that.googleDraw = new google.maps.drawing.DrawingManager({
              drawingMode: modeArr[index],
              drawingControl: true,
              drawingControlOptions: {
                  position: google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: ['polygon']
              },
              ...opions
        });
        google.maps.event.addListener(that.googleDraw, 'polylinecomplete', function(polyline) { //测距功能
                var pointxy = polyline.getPath();
                var triangleCoords= [];
                for(var i=0; i<pointxy.length;i++){
                  var pointstemp =  new google.maps.LatLng(pointxy.getAt(i).lat(),pointxy.getAt(i).lng());
                  triangleCoords.push(pointstemp);
                }
                new google.maps.Polyline({
                        path: triangleCoords,
                        map: that.maps,
                        strokeColor: "#FF0000",
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                });
                //直接调用API接口来计算几条线段的长度和 单位米
                var distanceValue = google.maps.geometry.spherical.computeLength(triangleCoords);
                distanceValue = `${this.$t('message.totallength')}：${(distanceValue/1000).toFixed(2)}Km`;
                new MarkerWithLabel({
                    position: triangleCoords[triangleCoords.length-1],
                    icon: {
                      url: " "
                    },
                    map: that.maps, //地图实例
                    labelContent:distanceValue, //label的内容
                    // 调整框框
                    // labelAnchor: new google.maps.Point(googleAnchor.left, googleAnchor.top),
                    labelStyle: {
                      padding: "5px 5px",
                      color: '#000000',
                      backgroundColor: '#ffffff',
                      // borderRadius: '5px',
                      border:'1px solid #FF0000',
                      fontWeight: '400',
                      opacity: 1
                    }
                });
        }); 
        google.maps.event.addListener(that.googleDraw, 'markercomplete', function(event) { //自定义标注。
              var position = event.position;  
              var latLng = { lat: position.lat(), lng: position.lng() }; 
              that.mark = latLng; 
              that.setMarkImg(that.mark);
              that.dialogModifyMark = true;
        }); 
        google.maps.event.addListener(that.googleDraw, 'circlecomplete', function(circle) { //用户刚刚绘制的圆形。
              var radius = circle.getRadius();
              let userList = [];
              // let bounds = circle.getBounds();
              let array = that.deweight(that.coordinate);
              that.gooleCover.forEach((marker)=>{
                let mark = marker.getPosition();
                let bool = google.maps.geometry.spherical.computeDistanceBetween(mark, circle.getCenter());  //判断当前用户是否被框选
                let row = array.find(item => item.id == marker.id);
                if(bool <= radius && row){  //判断覆盖物是否在矩形内
                  userList.push(row);
                }
              })
              that.temporaryGroup(userList);
        }); 
        google.maps.event.addListener(that.googleDraw, 'polygoncomplete', function(event) {  //用户刚刚绘制的多边形。
              let arr = [];
              let userList = [];
              let array = that.deweight(that.coordinate);
              event.getPath().forEach(function(latLng){
                arr.push(new google.maps.LatLng(latLng.toString().replace(/[\(\)]/g, "")));
              });
              that.gooleCover.forEach((marker)=>{
                let mark = marker.getPosition();
                let bool = google.maps.geometry.poly.containsLocation(mark, event);  //判断当前用户是否被框选
                let row = array.find(item => item.id == marker.id);
                if(bool && row){  //判断覆盖物是否在矩形内
                  userList.push(row);
                }
              })
              that.temporaryGroup(userList);
        });

        google.maps.event.addListener(that.googleDraw, 'rectanglecomplete', function(event) {  //用户刚刚绘制的矩形
              var bounds = event.getBounds();
              // var start = bounds.getNorthEast();
              // var end = bounds.getSouthWest();
              // var center = bounds.getCenter();
              let userList = [];
              let array = that.deweight(that.coordinate);
              that.gooleCover.forEach((marker)=>{
                let mark = marker.getPosition();
                let bool = bounds.contains(mark);//判断是否在矩形里面
                let row = array.find(item => item.id == marker.id);
                if(bool && row){  //判断覆盖物是否在矩形内
                  userList.push(row);
                }
              })
              that.temporaryGroup(userList);
        });

        google.maps.event.addListener(that.googleDraw, 'overlaycomplete', function(event) {
              that.graphsDrawn = event.overlay;  //绘制好的图形
              // event.overlay.setMap(null);  //设置为空就是清除绘制
              // that.googleDraw.setMap(null);   //停止绘制
              setTimeout(()=>{
                that.closeGraphical();
              },300)
              if(that.editRegion == 1){
                that.startTime();
              }
        });
        
        that.googleDraw.setMap(that.maps);
      }
    },
    closeGraphical(type){     //关闭图形
      if(this.editRegion != 1){
        this.clearOverwrites();
      }
      if(type == 1){
        this.theFirstTime = 0;
        this.onmouseout();
        this.mapIconDisplay = false; 
      }else{
        this.$refs.mapcustomfence.selectState.isCustom = false;
      }
      this.isCustom = true;
      this.distinguishType = 0;
      if(this.graphsDrawn){
        this.graphsDrawn.setMap(null); //设置为空就是清除绘制
      }
      if(this.googleDraw){
        this.googleDraw.setMap(null);   //停止绘制
      }
    },
    /*画多边形*/
    drawPolylines() {
      // if(this.editRegion != 1){
      //   this.clearOverwrites();
      // }
      this.distinguishType = 1;
      this.drawShape();
    },
    /*画圆圈*/
    drawCircle() {
      if(this.editRegion != 1){
        this.clearOverwrites();
      }
      this.distinguishType = 2;
      this.drawShape();
    },
    /*画矩形*/
    drawRectangle() {
      if(this.editRegion != 1){
        this.clearOverwrites();
      }
      this.distinguishType = 3;
      this.drawShape();
    },
    /*返回上一步*/
    previousStep() {
      if(this.editRegion == 1 && this.distinguishType == 0){
        
        return
      }
      this.closeGraphical(1);
      if(this.editRegion != 1){
        this.editRegion = 1;
        this.emptyData();
      }
    },
    /*删除围栏区域*/
    deleteFenceArea() {
      if(this.editRegion == 1 && this.distinguishType == 0){
        
        return
      }
      this.closeGraphical(1);
      this.$refs.mapcustomfence.selectState.isCustom = false;
      if(this.editRegion != 1){
        this.editRegion = 1;
        this.emptyData();
      }
    },
    /*清除覆盖*/
    clearOverwrites() {
      if(this.graphsDrawn && this.distinguishType == 0){
        this.graphsDrawn.setMap(null);
      }
    },
    /*提示语*/
    hint(msg) {
      this.$MessageWarning(msg);
    },
    temporaryGroup(arr){  //呼叫临时群组
      if (arr.length > 0 && this.editRegion == 1) {
        if (this.type != 2) {
          EventBus.$emit("temporaryGroup", arr);
        }
      }else{
        this.$MessageWarning(this.$t('message.message.Unabletocreatetemporarygroup'));
      }
    },
    focus() {
      //获取焦点
      this.$store.state.isFocusing = true;
    },
    blur() {
      //失去焦点
      this.$store.state.isFocusing = false;
    },
    emptyData(){    //清空data值
      if(this.editRegion != 1){
        this.editRegion = 1;
      }
      this.search = "";
      this.recordType = 0; //记录类型
      this.pointPosition = [];
      this.$store.state.groupData = [];
      this.$store.state.efId = 0;
      this.dialogTitle = this.$t('message.Createregionalgroups');
      this.offsetLeft = 0;
      this.offsetTop = 0;
      this.isShowMouse = "none";
      this.regionType = 0;
      this.overlays = {};
      this.$store.state.efRemark = '1';
    }
  },
  watch: {
    //监听事件
    "$store.state.groupUserData": {
      handler(newValue) {
        if (newValue.length > 0 && this.frequency == 0) {
          this.frequency = 1;
          this.preventFrequentlyRefresh();
        }
      },
      // deep: true,
      immediate: true
    }
  }
};
</script>
<style scoped lang="less">
.box {
  overflow: hidden;
}

.baidu-api {
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
  align-items: center;
}

/* 去除百度地图版权那行字 和 百度logo */
.baidumap>.BMap_cpyCtrl {
  display: none !important;
}

// .BMap_noprint {
//   display: block !important;
// }

.anchorBL {
  display: none;
}

.search {
  width: 455px;
  position: absolute;
  top: 10px;
  left: 10px;
  // width: 418px;
  height: 40px;
  overflow: hidden;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px;

  &_x {
    width: 50px;
    height: 100%;
    line-height: 37px;
    border-left: 1px solid #e2e2e2;
    color: #ccc;
    font-size: 30px;
    font-weight: 400;
  }

  &_input {
    flex: 1;
    height: 100%;
    background-color: #fff;
    color: #696969 !important;

    /deep/.el-input__inner {
      width: calc(100% - 42px);
      // width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #fff;
      color: #696969 !important;
      border: none;
      border-radius: 0px;
      padding: 0px;
    }
  }

  &_but {
    width: 58px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 17px;
      height: 17px;
    }
  }

  // &_title {
  //   width: 100px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }
}

.search_title {
  // width: 00px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.flex_align {
  display: flex;
  align-items: center;
}

#mouseOffset {
  color: red;
  font-size: 13px;
  background-color: rgb(255, 255, 255);
  padding: 0px 5px;
  border: 1px solid rgb(0, 0, 0);
}

/deep/.baidu_lAbel_triangle {
  width: 0px;
  border-style: solid;
  border-color: transparent;
  border-width: 10px 10px 8px 10px;
  border-top-color: var(--main-color);
  opacity: 1;
  margin: auto;
  position: absolute;
  left: 45%;
  bottom: -17px;
  z-index: 3;
}
</style>
